@charset "utf-8";

// font
$body-family: "Nunito", sans-serif;

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url(../static/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: 'Nunito-ExtraLight';
  font-style: normal;
  font-weight: 400;
  src: url(../static/fonts/Nunito-ExtraLight.ttf);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../static/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../static/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(../static/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(../static/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$primary: hsl(212, 55%, 48%);

.small-padding-top {
  padding: 2rem 1.5rem !important;
}

.remove-padding-bottom {
  padding-bottom: 0 !important;
}

.is-child {
  cursor: pointer;
}

.is-child:hover {
  background-color: hsl(0, 0%, 96%);
}

.hz-label-150 div.field-label {
  min-width: 150px;
}

.hz-label-200 div.field-label {
  min-width: 200px;
}

.hz-label-250 div.field-label {
  min-width: 250px;
}

.hz-label-300 div.field-label {
  min-width: 300px;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.greyish-color {
  color: hsl(0, 0%, 48%)
}

.area-selection:hover {
  background-color: hsl(0, 0%, 97%);
}

.is-grey-lighter {
  background-color: hsl(0, 0%, 86%) !important;
}

.area-selection-danger:hover {
  background-color: hsl(0, 0%, 97%);
  color: hsl(348, 100%, 61%);
}

.area-selection-info:hover {
  background-color: hsl(0, 0%, 97%);
  color: hsl(204, 86%, 53%);
}

@import "~trix/dist/trix";
@import "~bulma/bulma";
@import "~bulma-timeline/dist/css/bulma-timeline";
@import "~bulma-tagsinput/dist/css/bulma-tagsinput";
@import "~bulma-switch/dist/css/bulma-switch.min.css";

/* for change log lists */
ul.chlog {
  list-style-type: square;
}

ul ul.chlog {
  list-style-type: square;
}

.message {
  border-radius: 0;
}

.message-body {
  border-radius: 0;
}

.trix-content {
  height: 400px !important;
  max-height: 400px !important;
  overflow-y: auto !important;
}

.attachment img {
  max-height: 80vh !important;
  width:auto;
}

/* background color for tables (just a bit brigther) */
.has-background-danger2 {
  background-color: #fbc8d2 !important;
}

/* application spinner */
.spinner {
  margin-left: auto;
  margin-right: auto;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* for change git repo */
.gitrepo {
  list-style-type: square;
}

.no-underline {
  text-decoration: none !important;
}

.card {
  height: 100%;
  display:flex;
  flex-direction: column;
}

.ho:hover {
  background-color: hsl(206, 70%, 92%) !important;
}

.card-content {
  height: 100%;
}

.modal-full {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0;
  background-color: #fff;
}
